import { useState, useRef, useEffect } from "react";
import "./AudioPlayer.scss";

const AudioPlayer = ({ src, isPlaying, setIsPlaying }: any) => {
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(20);
  const audioRef = useRef(null);

  useEffect(() => {
    const audio: any = audioRef.current;

    const updateProgress = () => {
      setProgress((audio.currentTime / audio.duration) * 100);
    };

    if (audio) {
      audio.addEventListener("timeupdate", updateProgress);
      return () => {
        audio.removeEventListener("timeupdate", updateProgress);
      };
    }
  }, []);

  useEffect(() => {
    const audio: any = audioRef.current;
    if (isPlaying) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    const audio: any = audioRef.current;
    audio.volume = volume / 900;
  }, [volume]);

  const togglePlayPause = () => {
    const audio: any = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleProgressBarClick = (e: any) => {
    const audio: any = audioRef.current;
    const barWidth = e.currentTarget.getBoundingClientRect().width; // Get width of progress bar
    const clickPosition = e.clientX - e.currentTarget.getBoundingClientRect().left; // Get position relative to progress bar
    const clickPositionPercent = clickPosition / barWidth;
    audio.currentTime = clickPositionPercent * audio.duration;
  };

  const handleVolumeBarClick = (e: any) => {
    const barWidth = e.currentTarget.getBoundingClientRect().width; // Get width of volume bar
    const clickPosition = e.clientX - e.currentTarget.getBoundingClientRect().left; // Get position relative to volume bar
    const clickPositionPercent = clickPosition / barWidth;
    setVolume(clickPositionPercent * 100);
  };

  return (
    <div className="audio-control">
      <audio ref={audioRef}>
        <source src={src} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
      <button
        id="play-pause-btn"
        className={isPlaying ? "pause" : "play"}
        onClick={togglePlayPause}>
        {isPlaying ? "Pause" : "Play"}
      </button>
      <div className="progress-bar" onClick={handleProgressBarClick}>
        <div className="progress" style={{ width: `${progress}%` }}></div>
      </div>
      <div className="volume-control" onClick={handleVolumeBarClick}>
        <div className="volume-level" style={{ width: `${volume}%` }}></div>
      </div>
    </div>
  );
};

export default AudioPlayer;
