import AudioPlayer from "../AudioPlayer/AudioPlayer";
import musicBanner from "../../Assets/About/music-banner.jpg";
import musicFile from "../../Assets/About/guga-saudades.mp3";
import { IoIosCloseCircle } from "react-icons/io";
import "./MusicContent.scss";

const MusicContent = ({ isPlaying, setIsPlaying, hide }: any) => {
  return (
    <>
      <div className="info-container">
        <div className="banner-container">
          <img src={musicBanner} alt="Music Banner" />
        </div>
        <div className="text-container">
          <h5>
            <a
              href="https://open.spotify.com/intl-pt/album/71v5WtAmhAec4TaepatISH"
              target="_blank"
              rel="noopener noreferrer">
              Saudades
            </a>
          </h5>
          <h6>
            <b>Guga</b>
          </h6>
        </div>
      </div>
      <div className="audio-control">
        <AudioPlayer src={musicFile} isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
      </div>
      <div className="close-btn" onClick={hide}>
        <IoIosCloseCircle />
      </div>
    </>
  );
};

export default MusicContent;
