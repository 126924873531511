import React, { useState, useEffect, useRef, RefObject } from "react";
import "./FlappyBird.scss";

const GRAVITY = 15;
const JUMP_HEIGHT = 50;

type PipeType = {
  left: number;
  top: boolean;
  height: number;
  passed: boolean;
};

type PipeRef = React.RefObject<HTMLDivElement>;

function FlappyBird({
  setScore,
  setgameFinished,
  pauseGame,
  setPauseGame,
  isGameStarted,
  setIsGameStarted,
  gameFinished,
}: any) {
  const [birdPosition, setBirdPosition] = useState<number>(150);
  const [pipes, setPipes] = useState<PipeType[]>([]);
  const [playerMovementIntervalId, setPlayerMovementIntervalId] = useState<NodeJS.Timeout>();
  const [isBirdInLastMovenment, setIsBirdInLastMovenment] = useState<boolean>(false);
  const [pipeIntervalId, setPipeIntervalId] = useState<NodeJS.Timeout>();
  const [pipeCreationIntervalId, setPipeCreationIntervalId] = useState<NodeJS.Timeout>();

  const gameContainerRef = useRef<HTMLDivElement>(null);
  const birdRef = useRef<HTMLDivElement>(null);
  const [pipeRefs, setPipeRefs] = useState<(PipeRef | null)[]>([]);

  const containerClick = () => {
    if (isGameStarted) {
      if (isBirdInLastMovenment || pauseGame) return;
      clearInterval(playerMovementIntervalId);
      startPlayerMovement(false);
    } else {
      setScore(0);
      setIsGameStarted(true);
      startPlayerMovement();
      startPipes();
    }
  };

  // pipes logic
  function startPipes() {
    createPipes();
    const interval = setInterval(() => {
      movePipes();
    }, 16);
    setPipeIntervalId(interval);
  }

  function createPipes() {
    let lastPipeLeft: any = gameContainerRef.current?.clientWidth || 0;

    const interval = setInterval(() => {
      const lastPipeRef: any = pipeRefs[pipeRefs.length - 1];

      if(lastPipeRef){
        lastPipeLeft = lastPipeRef?.getBoundingClientRect().left || lastPipeLeft;
        lastPipeLeft += 150;
      } else {
        lastPipeLeft = (gameContainerRef.current?.clientWidth || 0) + 150;
      }

      if(lastPipeLeft > (gameContainerRef.current?.clientWidth || 0) + (150 * 5) || !gameContainerRef.current) return;

      const rectStyle = gameContainerRef.current.getBoundingClientRect();
      const newPipeTop = {
        left: lastPipeLeft,
        top: false,
        height: Math.max(Math.floor(Math.random() * rectStyle.height) - 200, 60),
        passed: false,
      };
      const newPipeBottom = {
        left: lastPipeLeft,
        top: true,
        height: Math.max(Math.floor(Math.random() * rectStyle.height) - 200, 60),
        passed: false,
      };
      setPipes(prevPipes => [...prevPipes, newPipeTop, newPipeBottom]);
    }, 1000);

    setPipeCreationIntervalId(interval);
  }

  function movePipes() {
    setPipes(prevPipes => {
      const cleanedPipes = prevPipes.filter(pipe => pipe.left > 0);

      setPipeRefs(prevPipeRefs => {
        const updatedPipeRefs = prevPipeRefs.filter((pipeRef: any, index) => {
          return pipeRef && pipeRef.getBoundingClientRect().left > 0;
        });
        return updatedPipeRefs;
      });

      return cleanedPipes.map(pipe => {
        return {
          ...pipe,
          left: pipe.left - 3,
        };
      });
    });
  }

  // player logic
  function startPlayerMovement(moveDown: boolean = true) {
    if (moveDown) {
      let interval = setInterval(() => {
        movePlayerDown();
      }, 200);
      setPlayerMovementIntervalId(interval);
    } else {
      movePlayerUp();
      setIsBirdInLastMovenment(true);
      let interval = setInterval(() => {
        setIsBirdInLastMovenment(false);
        movePlayerDown();
      }, 200);
      setPlayerMovementIntervalId(interval);
    }
  }

  function movePlayerUp() {
    setBirdPosition(prevPosition => prevPosition - JUMP_HEIGHT);
  }

  function movePlayerDown() {
    setBirdPosition(prevPosition => prevPosition + GRAVITY);
  }

  function checkIfBirdCollided() {
    if (!gameContainerRef.current || !birdRef.current) return;

    const containerRect = gameContainerRef.current.getBoundingClientRect();
    const containerTopOffset = containerRect.top;
    const containerHeight = containerRect.height;

    const birdRect = birdRef.current.getBoundingClientRect();
    const birdPositionY = birdRect.top - containerTopOffset;
    const birdPositionX = birdRect.left;

    pipeRefs.forEach((pipeRef: any, index: number) => {
      if (!pipeRef) return;

      const pipeRect = pipeRef.getBoundingClientRect();
      const pipeTop = pipeRect.top - containerTopOffset;
      const pipeBottom = pipeRect.bottom - containerTopOffset;
      const pipeLeft = pipeRect.left;
      const pipeRight = pipeRect.right;

      if (
        birdPositionX < pipeRight &&
        birdPositionX + birdRect.width > pipeLeft &&
        birdPositionY < pipeBottom &&
        birdPositionY + birdRect.height > pipeTop
      ) {
        endGame();
      }

      const birdLeft = birdRect.left;

      const pipe = pipes[index];
      if (pipe && birdLeft > pipeRight && !pipe.passed && !pipe.top) {
        setScore((prevScore: number) => prevScore + 1);
        setPipes(prevPipes => {
          const newPipes = [...prevPipes];
          newPipes[index].passed = true;
          return newPipes;
        });
      }
    });

    if (birdPositionY <= 0 || birdPositionY >= containerHeight) {
      endGame();
    }
  }

  function endGame() {
    clearInterval(playerMovementIntervalId);
    clearInterval(pipeCreationIntervalId);
    clearInterval(pipeIntervalId);
    setIsBirdInLastMovenment(false);
    setIsGameStarted(false);
    setgameFinished(true);
    setBirdPosition(150);
    setPipes([]);
  }

  function resumeBird() {
    startPlayerMovement();
    startPipes();
  }

  function pauseBird() {
    clearInterval(playerMovementIntervalId);
    clearInterval(pipeIntervalId);
    clearInterval(pipeCreationIntervalId);
  }

  useEffect(() => {
    checkIfBirdCollided();
  }, [birdPosition]);

  useEffect(() => {
    if (pauseGame) {
      pauseBird();
    } else if (!pauseGame && isGameStarted) {
      resumeBird();
    }
  }, [pauseGame]);

  return (
    <div className="game-container" onClick={containerClick} ref={gameContainerRef}>
      {pauseGame && (
        <div className="game-paused" onClick={() => setPauseGame(false)}>
          <h3>Game Paused</h3>
          <a>Click to continue.</a>
        </div>
      )}
      {!isGameStarted && !gameFinished && (
        <div className="game-paused">
          <h3>Start Game</h3>
          <h4>Click to start</h4>
        </div>
      )}
      <div className="bird" ref={birdRef} style={{ top: birdPosition }} />
      {pipes.map((pipe, index) => (
        <div
          key={index}
          ref={(el: any) => (pipeRefs[index] = el)}
          className={`pipe ${pipe.top ? "top" : "bottom"}`}
          style={{
            left: pipe.left,
            top: pipe.top ? 0 : "auto",
            bottom: pipe.top ? "auto" : 0,
            height: pipe.height,
          }}
        />
      ))}
    </div>
  );
}

export default FlappyBird;
