import { useState } from "react";
import { CiPause1, CiPlay1 } from "react-icons/ci";
import { TbPinnedOff } from "react-icons/tb";
import { VscPinned } from "react-icons/vsc";
import { IoIosCloseCircle } from "react-icons/io";
import FlappyBird from "../FlappyBird/FlappyBird";
import "./GameContent.scss";

function GameContent({ hide, pin }: any) {
  const [isPinned, setIsPinned] = useState<boolean>(false);
  const [score, setScore] = useState<number>(0);
  const [gameFinished, setgameFinished] = useState<boolean>(false);
  const [pauseGame, setPauseGame] = useState<boolean>(false);
  const [isGameStarted, setIsGameStarted] = useState<boolean>(false);

  function pinCard(e: any) {
    pin(!isPinned);
    setIsPinned(!isPinned);
  }

  function pauseGameClick(e: any) {
    if (isGameStarted && !gameFinished) {
      setPauseGame(!pauseGame);
    }
  }

  function hideClick(e: any) {
    if (isGameStarted && !gameFinished && !pauseGame) {
      setPauseGame(true);
    }
    hide();
  }

  return (
    <>
      <div className="content-container">
        <div className="game-score">
          <h5>
            Score: <b>{score}</b>
          </h5>
        </div>
        <FlappyBird
          setgameFinished={setgameFinished}
          gameFinished={gameFinished}
          setScore={setScore}
          pauseGame={pauseGame}
          setPauseGame={setPauseGame}
          isGameStarted={isGameStarted}
          setIsGameStarted={setIsGameStarted}
        />
      </div>
      {gameFinished && (
        <div className="end-info">
          <div className="dark-bg"></div>
          <div className="text">
            <h3>Game Over</h3>
            <h3>
              Your Score: <b>{score}</b>
            </h3>
            <a onClick={() => setgameFinished(false)}>Go again</a>
          </div>
        </div>
      )}
      <div className="pin-btn" onClick={pinCard}>
        {isPinned ? <TbPinnedOff /> : <VscPinned />}
      </div>
      <div className="pause-btn" onClick={pauseGameClick}>
        {pauseGame ? <CiPlay1 /> : <CiPause1 />}
      </div>
      <div className="close-btn" onClick={hideClick}>
      <IoIosCloseCircle />
      </div>
    </>
  );
}

export default GameContent;
