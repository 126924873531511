import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Topbar from "./Topbar/Topbar";
import Home from "./Home/Home";
import About from "./About/About";
import Timeline from "./Timeline/Timeline";
import Projects from "./Projects/Projects";
import "./App.css";

function App() {
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/' && <Topbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/timeline" element={<Timeline />} />
        <Route path="/projects" element={<Projects />} />
      </Routes>
    </>
  );
}

export default function Root() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
