import DragableCard from "../Components/DragableCard/DragableCard";
import MusicContent from "../Components/MusicContent/MusicContent";
import GameContent from "../Components/GameContent/GameContent";
import PictureContent from "../Components/PictureContent/PictureContent";
import "./About.scss";
import VideoContent from "../Components/VideoContent/VideoContent";

function About() {

  function show(className: string) {
    const div = document.querySelector(className);
    if (!div) return;

    div.classList.remove("hide");
    div.classList.add("show");
  }

  function linkClick(link: string | undefined) {
    if (link) window.open(link, "_blank");
  }

  return (
    <div id="about" className="container-size">
      <div className="main-text-container">
        <h4>
          My name? <b>António Vaz</b>. My age? <b style={{textDecoration: 'line-through'}}>19,</b> <b>20</b>. Where I am from? <b>Lisbon, Portugal</b>.
          ?
        </h4>
        <p>
          I love programming and building different projects that might not make sense, getting
          <span className="error color-hover"> errors</span> that shouldn't be there, and{" "}
          <span className="error color-hover">bugs</span> that might be easier to call an
          exterminator but I still enjoy it. Besides programming, I have also been getting into{" "}
          <b>electronics</b> which still involves coding but what I love is getting the components
          physically together with the code and making something that might be useless but I like
          it.
        </p>
        <p>
          Ok outside technology I also enjoy reading my favorite book so far as been{"  "}
          <span
            className="dotted color-hover"
            onClick={() =>
              linkClick(
                "https://www.bertrand.pt/livro/tudo-foi-como-tinha-de-ser-raul-minh-alma/29393759"
              )
            }>
            "Tudo foi como tinha de ser"
          </span>{" "}
          <span style={{ fontSize: "10px" }}>by</span>{" "}
          <span
            className="dotted color-hover"
            onClick={() => linkClick("https://www.bertrand.pt/autor/raul-minh-alma/2926452")}>
            Raul Minh'Alma
          </span>{" "}
          who is also my favorite author. On a completely different side I also surf and it is one of
          my favorite hobbies if you are in the weekend and it's before 3pm i am probably at the beach surfing.
        </p>
        <p>Of course, I also enjoy playing video games. <br /><span style={{fontSize: '13px'}}>(Haven't played in quite some time but used to enjoy it.)</span></p>
        <h5 className="dashed">
          <span style={{ fontSize: "13px", fontStyle: "normal" }}>
            Don't forget to click on the items in the backcards
          </span>
        </h5>
        <div className="image">
          <img src={require(`../Assets/About/me.jpg`)} alt="Me" />
        </div>
      </div>
      <div className="cards-container">
        <div className="card first">
          <h5>Countrys i have visited</h5>
          <ul>
            {/* <li className="color-hover" onClick={() => show(".box-drop.picture.picture-one.hide")}>
              portugal_♾️_false_true.jpg
            </li> */}
            <li className="color-hover" onClick={() => show(".box-drop.picture.picture-two.hide")}>
              brazil.jpg
            </li>
            <li
              className="color-hover"
              onClick={() => show(".box-drop.picture.picture-three.hide")}>
              france.jpg
            </li>
            <li className="color-hover" onClick={() => show(".box-drop.picture.picture-four.hide")}>
              italy.jpg
            </li>
          </ul>
        </div>
        <div className="card second">
          I also enjoy water activities
          <ul>
          <li
              className="color-hover"
              onClick={() => show(".box-drop.video.video-one.hide")}>
              kayake.mp4
            </li>
            <li
              className="color-hover"
              onClick={() => show(".box-drop.video.video-two.hide")}>
              surfing.mp4
            </li>
          </ul>
        </div>
        <div className="card third">
          <ul>
            <li className="color-hover" onClick={() => show(".box-drop.music.hide")}>
              Favourite music ?
            </li>
            <li className="color-hover" onClick={() => show(".box-drop.game.hide")}>
              Time spending game ?
            </li>
          </ul>
         </div>
      <DragableCard className="box-drop music hide" stopMusic={true} id="musicContent">
        <MusicContent />
      </DragableCard>
      <DragableCard className="box-drop game hide" id="gameContent">
        <GameContent />
      </DragableCard>
      {/* <DragableCard className="box-drop picture picture-one hide" id="pictureOne">
        <PictureContent fileName="portugal.jpg" textShow="Portugal" />
      </DragableCard> */}
      <DragableCard className="box-drop picture picture-two hide" id="pictureTwo">
        <PictureContent fileName="brazil.jpg" textShow="Brazil (2019, 2021)" />
      </DragableCard>
      <DragableCard className="box-drop picture picture-three hide" id="pictureThree">
        <PictureContent fileName="france.png" textShow="France (2018, 2024)" />
      </DragableCard>
      <DragableCard className="box-drop picture picture-four hide" id="pictureFour">
        <PictureContent fileName="italy.png" textShow="Italy (2022, 2023)" />
      </DragableCard>
      <DragableCard className="box-drop video video-one hide" id="videoOne">
        <VideoContent fileName="kayake.mp4" />
      </DragableCard>
      <DragableCard className="box-drop video video-two hide" id="videTwo">
        <VideoContent fileName="Surfing.mp4" />
      </DragableCard>
      </div>
    </div>
  );
}

export default About;
