import React, { ReactNode, cloneElement, useState } from "react";
import "./DragableCard.scss";

type DragableCardProps = {
  className: string;
  id: string;
  stopMusic?: boolean;
  children?: ReactNode;
};

const DragableCard = ({ className, stopMusic, children, id }: DragableCardProps) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  // Additional props to be passed to children
  const additionalProps = {
    isPlaying,
    setIsPlaying,
    hide,
    pin,
  };

  function hide(e: any) {
    if (e){
      e.preventDefault();
    }

    const classNameRemoveList = className.split(" ");
    classNameRemoveList[classNameRemoveList.length - 1] = "show";

    const classNameRemove = "." + classNameRemoveList.join(".");
    const div = document.querySelector(classNameRemove);
    if (!div) return;

    if (stopMusic) {
      setIsPlaying(false);
    }

    div.classList.remove("show");
    div.classList.add("hide");
  }

  function pin(pin: boolean) {
    const classNameRemoveList = className.split(" ");
    classNameRemoveList[classNameRemoveList.length - 1] = "show";

    const classNameRemove = "." + classNameRemoveList.join(".");
    const div = document.querySelector(classNameRemove);
    if (!div) return;

    if (stopMusic) {
      setIsPlaying(false);
    }

    if (pin) {
      div.classList.add("pinned");
    } else {
      div.classList.remove("pinned");
    }
  }

  const handleMouseDragStart = (e: any) => {
    e.preventDefault();
    const div = document.getElementById(id);
    if (div) {
      const rect = div.getBoundingClientRect();
      setOffset({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
    }
    setDragging(true);
  };

  const handleMouseDrag = (e: any) => {
    e.preventDefault();
    if (dragging) {
      const div = document.getElementById(id);
      const parentDiv = div?.parentElement;
      if (div && parentDiv) {
        const parentRect = parentDiv.getBoundingClientRect();
        const x = e.clientX - parentRect.left - offset.x;
        const y = e.clientY - parentRect.top - offset.y;

        div.style.left = `${x}px`;
        div.style.top = `${y}px`;
      }
    }
  };

  const handleMouseDragEnd = () => {
    setDragging(false);
  };

  return (
    <div
      id={id}
      className={className}
      onMouseDown={handleMouseDragStart}
      onMouseMove={handleMouseDrag}
      onMouseUp={handleMouseDragEnd}
      onMouseLeave={handleMouseDragEnd}>
      {React.Children.map(children, child =>
        React.isValidElement(child) ? cloneElement(child, additionalProps) : child
      )}
    </div>
  );
};

export default DragableCard;
