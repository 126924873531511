import "./Projects.scss";

function Projects() {
  const projects = [
    {
      name: "Surflog",
      githubLink: "https://github.com/AntonioSilvaVaz/surflog",
      description:
        "Future/currently surf forecast, a surf diary, and an interactive map with surf spots.",
      image: "surflog.png",
      websiteLink: "https://surflog.antoniovazom.com/",
      techonogies: ["vue", "css", "net", "mysql", "stormglass", "openai"],
    },
    {
      name: "Shopping",
      description:
        "This project is a webs app that allows different users to create and sell different products.",
      image: "shopping.png",
      githubLink: "https://github.com/AntonioSilvaVaz/shopping",
      techonogies: ["next", "css", "typescript", "express", "node", "sqlserver"],
    },
    {
      name: "Albums",
      description:
        "Saving my images and videos from my phone to the app as an extra backup.",
      image: "albums.png",
      techonogies: ["react", "mui", "typescript", "sqlserver", "net"],
    },
    {
      name: "Track",
      description: "Helps me express my mind in a visual way. For example an app flow.",
      image: "track.png",
      githubLink: "https://github.com/AntonioSilvaVaz/track",
      techonogies: ["react", "typescript", "node", "express", "mongodb"],
    },
    {
      name: "Task Tracker",
      description: "A simple task tracker that saves data to local storage.",
      image: "task-tracker.png",
      githubLink: "https://github.com/AntonioSilvaVaz/rastreio-tarefas ",
      websiteLink: "https://task-tracker.antoniovazom.com/",
      techonogies: ["react", "css", "redux", "typescript"],
    },
    {
      name: "Visualizer",
      description: "Visualizing data from a database in a chart.",
      image: "visualizer.png",
      githubLink: "https://github.com/AntonioSilvaVaz/engine-ai",
      techonogies: ["react", "typescript", "prisma", "node", "express", "sass"],
    },
    {
      name: "Blackjack",
      description: "Double down, insurance, and reload when you lose it all.",
      image: "blackjack.png",
      githubLink: "https://github.com/AntonioSilvaVaz/blackjack",
      websiteLink: "https://blackjack.antoniovazom.com/",
      techonogies: ["html", "css", "javascript", "jquery"],
    },
    {
      name: "Astronaut",
      description: "Adding two things i love programming and space. A simple single page website that shows some data about space.",
      image: "astronaut.png",
      githubLink: "https://github.com/AntonioSilvaVaz/astronaut",
      websiteLink: "https://astronaut.antoniovazom.com/",
      techonogies: ["html", "css", "javascript", "jquery"],
    },
    {
      name: "LandEscaping",
      description: "A LandEscaping website with a contact form.",
      image: "land-escaping.png",
      techonogies: ["html", "css", "javascript"],
    },
  ];

  function linkClick(link: string | undefined) {
    if (link) window.open(link, "_blank");
  }

  return (
    <div id="project-content">
      <div className="projects-container">
        {projects.map((project, index) => (
          <div className="project" key={index}>
            <div className="project-name">
              <h4>{project.name}</h4>
            </div>
            <div className="project-image">
              <img
                src={require(`../Assets/ProjectsPictures/${project.image}`)}
                alt={project.name}
              />
            </div>
            <h5 className="project-description">{project.description}</h5>
            <div className="hover-project">
              {project.websiteLink && (
                <button onClick={() => linkClick(project.websiteLink)} className="project-button">
                  <h5>Website</h5>
                  <span>{project.websiteLink}</span>
                </button>
              )}
              {project.githubLink && (
                <button onClick={() => linkClick(project.githubLink)} className="project-button">
                  <h5>Github</h5>
                  <span>{project.githubLink}</span>
                </button>
              )}
              <div className="project-technologies">
                {project.techonogies.map((tech, index) => (
                  <div key={index} className="project-tech">
                    <img src={require(`../Assets/Technologies/${tech}.svg`)} alt={tech} />
                    <div className="bg"></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
