import "./Topbar.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosCloseCircle } from "react-icons/io";
import { useRef } from "react";

function Topbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const buttonsShow = ["Home", "About", "Timeline", "Projects"];
  const topbarButtonsRef = useRef<HTMLDivElement>(null);

  function isButtonActive(button: string): boolean {
    const currentButtonString = button === "Home" ? "/" : "/" + button.toLowerCase();
    return location.pathname === currentButtonString;
  }

  function navigatePage(page: string) {
    const pathGo = page === "Home" ? "/" : `/${page.toLowerCase()}`;
    const currentPath = location.pathname === "Home" ? "/" : `/${location.pathname.toLowerCase()}`;

    if (currentPath !== pathGo) {
      showButtonsNav(null);
      navigate(pathGo);
    }
  }

  function showButtonsNav(e: any) {
    if (!topbarButtonsRef.current) return;
    topbarButtonsRef.current.classList.toggle("show");
  }

  return (
    <div id="topbar">
      <div className="hamburger-icon">
        <RxHamburgerMenu onClick={showButtonsNav} />
      </div>
      <div className="topbar-buttons-container" ref={topbarButtonsRef}>
        {buttonsShow.map(button => (
          <button
            key={button}
            className={isButtonActive(button) ? "active" : "normal"}
            onClick={() => navigatePage(button)}>
            <h4>{button}</h4>
          </button>
        ))}
        <div className="close-icon">
          <IoIosCloseCircle onClick={showButtonsNav} />
        </div>
      </div>
    </div>
  );
}

export default Topbar;
