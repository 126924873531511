import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import "./Timeline.scss";

function Timeline() {
  const [timelineFillPercentage, setTimelineFillPercentage] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isMovingLefRight, setIsMovingLefRight] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const lineRef = useRef<HTMLDivElement | null>(null);
  const timelineRef = useRef<HTMLDivElement | null>(null);

  // 1 month -> 100 space
  const [moments, setMoments] = useState([
    {
      title: "Started Lisbon internship",
      date: "13/03/2022",
      space: 0,
      image: "status-logo.png",
      animated: false,
      link: "https://statusevents.pro/",
      crossed: false,
    },
    {
      title: "Started Milan internship",
      date: "01/06/2022",
      space: 300,
      image: "youtoo-logo.png",
      animated: false,
      link: "https://www.youtoo.digital/",
      crossed: false,
    },
    {
      title: "18 years on earth",
      date: "08/10/2022",
      space: 400,
      animated: false,
      crossed: false,
    },
    {
      title: "Finished Blogit",
      date: "22/04/2023",
      space: 600,
      image: "blogit_logo.png",
      animated: false,
      crossed: false,
    },
    {
      title: "Finished Minon mentor",
      date: "10/05/2023",
      space: 100,
      animated: false,
      crossed: false,
    },
    {
      title: "Finished bootcamp",
      date: "10/06/2023",
      space: 100,
      image: "codeworks-logo.png",
      animated: false,
      link: "https://codeworks.me/",
      crossed: false,
    },
    {
      title: "Started Project Shopping",
      date: "10/07/2023",
      space: 100,
      image: "shopping-picture.png",
      animated: false,
      link: "https://github.com/AntonioSilvaVaz/shopping",
      crossed: false,
    },
    {
      title: "19 years on earth",
      date: "08/10/2023",
      space: 300,
      animated: false,
      crossed: false,
    },
    {
      title: "Started at CPI Retail",
      date: "09/10/2023",
      space: 0,
      image: "cpi-logo.png",
      animated: false,
      link: "https://www.cpiretail.pt/",
      crossed: false,
    },
    {
      title: "MemoriesK",
      date: "23/03/2024",
      space: 500,
      image: "albums.png",
      animated: false,
      crossed: false,
    },
    {
      title: "France!",
      date: "15/06/2024",
      space: 300,
      image: "france.jpg",
      animated: false,
      crossed: false,
    },
    {
      title: "20. ",
      date: "08/10/2024",
      space: 400,
      animated: false,
      crossed: false,
    },
    {
      title: "Surflog",
      date: "18/10/2024",
      space: 0,
      image: "surflog.png",
      link: "https://surflog.antoniovazom.com/",
      animated: false,
      crossed: false,
    },
  ]);

  const handleMouseDown = (e: MouseEvent) => {
    e.preventDefault();
    if (!timelineRef.current) return;

    setIsDragging(true);
    setStartX(e.pageX - timelineRef.current.offsetLeft);
    setScrollLeft(timelineRef.current.scrollLeft);
  };

  const handleMouseMove = (e: MouseEvent) => {
    e.preventDefault();
    if (!isDragging || !timelineRef.current) return;

    const x = e.pageX - timelineRef.current.offsetLeft;
    const walk = (x - startX) * 1;
    const newScrollLeft = scrollLeft - walk;
    timelineRef.current.scrollLeft = newScrollLeft;
    animateImage();
    animateLine(newScrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleWheel = (e: any) => {
    if (timelineRef.current) {
      if (e.deltaX === 0) return;
      e.preventDefault();
      const newScrollLeft = timelineRef.current.scrollLeft + e.deltaX;
      timelineRef.current.scrollTo({
        left: newScrollLeft,
      });
      animateImage();
      animateLine(newScrollLeft);
    }
  };

  const handleScroll = () => {
    if (!timelineRef.current) return;
    const scrollLeft = timelineRef.current.scrollLeft;
    animateImage();
    setIsMovingLefRight(false);
    animateLine(scrollLeft);
  };

  function linkClick(link: string | undefined) {
    if (link) window.open(link, "_blank");
  }

  function animateImage() {
    const timeline = timelineRef.current;
    if (!timeline) return;

    const timelineRect = timeline.getBoundingClientRect();

    setMoments(moments => {
      return moments.map((moment, index) => {
        const element = document.getElementById(`moment-${index}`);
        if (!element) return moment;

        const rect = element.getBoundingClientRect();
        const momentLeft = rect.left - timelineRect.left;
        const visible = momentLeft < timeline.offsetWidth && momentLeft + rect.width >= 0;

        if (!moment.animated && visible) {
          moment.animated = true;
        }

        return moment;
      });
    });
  }

  function moveTimeline(move: "left" | "right") {
    if (isMovingLefRight) return;

    setIsMovingLefRight(true);
    if (timelineRef.current) {
      const scrollAmount = timelineRef.current.offsetWidth / 2;
      let amountScroll = 0;

      if (move === "left") {
        amountScroll = timelineRef.current.scrollLeft - scrollAmount;
      } else {
        const nextImageToAnimateIndex = moments.findIndex(moment => moment.animated === false);
        if (nextImageToAnimateIndex !== -1) {
          const element = document.getElementById(`moment-${nextImageToAnimateIndex}`);
          if (!element) return;

          const timeline = document.getElementById("timeline-information");
          if (!timeline) return;

          const timelineRect = timeline.getBoundingClientRect();

          const rect = element.getBoundingClientRect();
          const momentLeft = rect.left - timelineRect.left;

          amountScroll = timelineRef.current.scrollLeft + momentLeft - 215;
        } else {
          amountScroll = timelineRef.current.scrollLeft + scrollAmount;
        }
      }

      timelineRef.current.scroll({
        left: amountScroll,
        behavior: "smooth",
      });
    }
  }

  function animateLine(scrollPosition: number) {
    const timeline = timelineRef.current;
    if (!timeline) return;

    const scrollWidth = timeline.scrollWidth;
    const clientWidth = timeline.clientWidth;
    const scrollPercentage = Math.min(
      Math.max(Math.trunc((scrollPosition / (scrollWidth - clientWidth)) * 100), 0),
      100
    );
    setTimelineFillPercentage(scrollPercentage);

    setMoments(moments => {
      return moments.map((moment, index) => {
        const element = document.getElementById(`point-${index}`);
        if (!element) return moment;

        const timelineRect = timeline.getBoundingClientRect();
        const rect = element.getBoundingClientRect();

        const crossingLinePosition =
          (scrollPercentage / 100) * timelineRect.width + timelineRect.left;
        const crossed = rect.left <= crossingLinePosition;

        moment.crossed = crossed;

        if (crossed) {
          element.classList.add("crossed");
        } else {
          element.classList.remove("crossed");
        }

        return moment;
      });
    });
  }

  useEffect(() => {
    setTimeout(() => {
      animateImage();
      animateLine(0);
    }, 300);
  }, []);

  useEffect(() => {
    const lineElement = lineRef.current;
    if (lineElement) {
      lineElement.addEventListener("wheel", handleWheel);
      lineElement.addEventListener("mousedown", handleMouseDown);
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    const timelineElement = timelineRef.current;
    if (timelineElement) {
      timelineElement.addEventListener("wheel", handleWheel);
      timelineElement.addEventListener("mousedown", handleMouseDown);
      timelineElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (lineElement) {
        lineElement.removeEventListener("mousedown", handleMouseDown);
      }
      if (timelineElement) {
        timelineElement.removeEventListener("wheel", handleWheel);
        timelineElement.removeEventListener("scroll", handleScroll);
      }
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, startX, scrollLeft]);

  return (
    <div id="timeline" className="container-size">
      <div id="timeline-content">
        <div className="text-container">
          <h1>Feel free to go discover more</h1>
          <p>
            There is much more than what can be showed in the timeline, a lot of time spent
            learning, failing, researching and not stopping i hope with this you can have a better
            overview of my carrear and a few of my projects.
          </p>
        </div>
        <div id="timeline-information" ref={timelineRef}>
          {moments.map((moment, index) => (
            <div
              id={`${"moment-" + index}`}
              className="moment"
              style={{
                marginLeft: moment.space,
                animation: moment.animated ? "moment-in 1s ease-in-out 100ms forwards" : "",
              }}
              key={index}>
              <div className={`image ${moment.crossed && "crossed-image"}`}>
                {moment.image && (
                  <img
                    src={require(`../Assets/TimelinePictures/${moment.image}`)}
                    alt={moment.title}
                  />
                )}
              </div>
              <div id={`${"point-" + index}`} className="point"></div>
              <div className="moment-info-container">
                <div
                  className={`title ${moment.link && "link"}`}
                  onClick={() => linkClick(moment.link)}>
                  <h4>{moment.title}</h4>
                </div>
                <div className="date">
                  <h5>{moment.date}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="timeline-container">
          <div className="line" ref={lineRef}>
            <div
              className="inner-line"
              style={{
                width: `${timelineFillPercentage}%`,
              }}></div>
            <span className="left-button arrow-button" onClick={() => moveTimeline("left")}>
              <FaArrowLeft className="arrow" />
            </span>
            <span className="right-button arrow-button" onClick={() => moveTimeline("right")}>
              <FaArrowRight className="arrow" />
            </span>
          </div>
          <div className="helper-text">
            <h5>Move your cursor horizontally or move the timeline</h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timeline;
