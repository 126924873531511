import { getRandomColor } from "../Utils/Colors";
import { useLocation, useNavigate } from "react-router-dom";
import { FaGithub } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { IoIosPaper } from "react-icons/io";
import { PiArrowBendRightDownBold } from "react-icons/pi";
// @ts-ignore
import CV from "../Assets/cv.pdf";
import "./Home.scss";

function Home() {
  const navigate = useNavigate();
  const buttonsShow = ["Home", "About", "Timeline", "Projects"];
  const location = useLocation();

  function updateColors() {
    // Generate random colors
    const originalColors = {
      primary: getRandomColor("lighter"),
      secondary: getRandomColor("normal"),
      tertiary: getRandomColor("lighter"),
      quaternary: getRandomColor("normal"),
      background: getRandomColor("darker"),
    };

    // Set the CSS variables
    document.documentElement.style.setProperty("--primary", originalColors.primary);
    document.documentElement.style.setProperty("--secondary", originalColors.secondary);
    document.documentElement.style.setProperty("--tertiary", originalColors.tertiary);
    document.documentElement.style.setProperty("--quaternary", originalColors.quaternary);
    document.documentElement.style.setProperty("--bg-color", originalColors.background);
  }

  function navigatePage(page: string) {
    const pathGo = page === "Home" ? "/" : `/${page.toLowerCase()}`;
    const currentPath = location.pathname === "Home" ? "/" : `/${location.pathname.toLowerCase()}`;

    if (currentPath !== pathGo) {
      navigate(pathGo);
    }
  }

  function linkClick(link: string | undefined) {
    if (link) window.open(link, "_blank");
  }

  return (
    <>
      <div id="home" className="container-size">
        <div className="main-container">
          <div className="text-container">
            <h1>Welcome!</h1>
            <div className="website-container">www.antoniovazom.com</div>
          </div>
          <div className="nav-buttons-container">
            {buttonsShow.map(button => (
              <button
                key={button}
                className={button === "Home" ? "active" : "normal"}
                onClick={() => navigatePage(button)}>
                <h4>{button}</h4>
              </button>
            ))}
          </div>
        </div>
        <div className="bottom-container">
          <div className="left-container">
            <h5>
              Don’t like it?{" "}
              <br />
              Refresh colors{" "}
              <span className="refresh-text" onClick={updateColors}>
                here
              </span>
            </h5>
            <div className="colors-container">
              <div className="primary"></div>
              <div className="secondary"></div>
              <div className="tertiary"></div>
              <div className="quaternary"></div>
            </div>
          </div>
          <div className="right-container">
            <div
              className="social-media-container"
              onClick={() => linkClick("https://github.com/AntonioSilvaVaz")}>
              <FaGithub />
            </div>
            <div
              className="social-media-container"
              onClick={() => linkClick("https://www.linkedin.com/in/antonio-silva-vaz/")}>
              <FaLinkedinIn />
            </div>
            <div className="download-cv-container">
              <div className="cv-container">
                <h5 className="text">
                  Download my cv <span className="underline-text">here</span>
                </h5>
                <PiArrowBendRightDownBold className="arrow" />
              </div>
              <div className="social-media-container">
                <a href={CV} download="antonio_vaz_cv.pdf" target="_blank" rel="noreferrer">
                  <IoIosPaper />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="background-container">
      </div>
    </>
  );
}

export default Home;
