export function getRandomColor(brightness = "normal") {
  let color = getRandomHexColor();

  if (brightness.toLowerCase() === "lighter") {
    color = adjustLuminance(color, 0.7, 1); // Ensure the color is in the lighter range
  } else if (brightness.toLowerCase() === "darker") {
    color = adjustLuminance(color, 0, 0.3); // Ensure the color is in the darker range
  }

  return color;
}

function getRandomHexColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function adjustLuminance(hex: string, minLuminance: number, maxLuminance: number) {
  let [r, g, b] = hexToRgb(hex);
  let luminance = getLuminance(r, g, b);

  while (luminance < minLuminance || luminance > maxLuminance) {
    hex = getRandomHexColor();
    [r, g, b] = hexToRgb(hex);
    luminance = getLuminance(r, g, b);
  }

  return hex;
}

function hexToRgb(hex: string) {
  let bigint = parseInt(hex.slice(1), 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return [r, g, b];
}

export function rgbToHex(rgb: string) {
  const regex = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/;
  const match = rgb.match(regex);
  if (!match) {
    return;
  }

  const r = parseInt(match[1]);
  const g = parseInt(match[2]);
  const b = parseInt(match[3]);

  const hex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

  return hex;
}

function getLuminance(r: any, g: any, b: any) {
  let a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}
