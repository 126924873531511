import { IoIosCloseCircle } from "react-icons/io";
import "./VideoContent.scss";
import { useEffect, useRef, useState } from "react";

const VideoContent = ({ fileName, hide }: any) => {
  let VideoContent: any;
  if (fileName === "kayake.mp4") {
    VideoContent = require(`../../Assets/About/${fileName}`);
  }
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(20);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    if (!VideoContent) return;
    const video: any = videoRef.current;

    const updateProgress = () => {
      setProgress((video.currentTime / video.duration) * 100);
    };

    if (video) {
      video.addEventListener("timeupdate", updateProgress);
      return () => {
        video.removeEventListener("timeupdate", updateProgress);
      };
    }
  }, []);

  useEffect(() => {
    if (!VideoContent) return;

    const video: any = videoRef.current;
    if (isPlaying) {
      video.play();
    } else {
      video.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (!VideoContent) return;

    const video: any = videoRef.current;
    video.volume = volume / 900;
  }, [volume]);

  const togglePlayPause = () => {
    const video: any = videoRef.current;
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleProgressBarClick = (e: any) => {
    const video: any = videoRef.current;
    const barWidth = e.currentTarget.getBoundingClientRect().width; // Get width of progress bar
    const clickPosition = e.clientX - e.currentTarget.getBoundingClientRect().left; // Get position relative to progress bar
    const clickPositionPercent = clickPosition / barWidth;
    video.currentTime = clickPositionPercent * video.duration;
  };

  const handleVolumeBarClick = (e: any) => {
    const barWidth = e.currentTarget.getBoundingClientRect().width; // Get width of volume bar
    const clickPosition = e.clientX - e.currentTarget.getBoundingClientRect().left; // Get position relative to volume bar
    const clickPositionPercent = clickPosition / barWidth;
    setVolume(clickPositionPercent * 100);
  };

  return (
    <div className="video-container">
      {VideoContent ? (
        <>
          <div className="video-holder">
            <video src={VideoContent} ref={videoRef}></video>
          </div>
          <div className="controls-container">
            <button
              id="play-pause-btn"
              className={isPlaying ? "pause" : "play"}
              onClick={togglePlayPause}>
              {isPlaying ? "Pause" : "Play"}
            </button>
            <div className="progress-bar" onClick={handleProgressBarClick}>
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
            <div className="volume-control" onClick={handleVolumeBarClick}>
              <div className="volume-level" style={{ width: `${volume}%` }}></div>
            </div>
          </div>
        </>
      ) : (
        <div className="no-video-message">
          Sorry, I don't have a video about this topic. I wish I had that's why I placed it here
          because I hoped to have one the day I published the website.{" "}
        </div>
      )}
      <div className="close-btn" onClick={hide}>
        <IoIosCloseCircle />
      </div>
    </div>
  );
};

export default VideoContent;
