import { IoIosCloseCircle } from "react-icons/io";
import "./PictureContent.scss";

const PictureContent = ({ fileName, textShow, hide }: any) => {
  const pictureContent = require(`../../Assets/About/${fileName}`);

  return (
    <div className="picture-container">
      <div className="image-holder">
        <img src={pictureContent} alt="dog" />
      </div>
      <div className="text">
        <h4>{textShow}</h4>
      </div>
      <div className="close-btn" onClick={hide}>
        <IoIosCloseCircle />
      </div>
    </div>
  );
};

export default PictureContent;
